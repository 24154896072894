import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { LocationModel } from '@nimles/models';

declare global {
  interface Window {
    google: any;
  }
}
export interface LatLng {
  lat: number;
  lng: number;
}

const containerStyle = {
  width: '100%',
  flex: 1,
  minHeight: '300px',
  borderRadius: '4px',
};

interface BoundsModel {
  northEast: LocationModel;
  southWest: LocationModel;
}

interface Props {
  center?: LocationModel;
  zoom?: number;
  locations?: LocationModel[];
  mapStyle?: any;
  fitLocations?: boolean;
  disableDefaultUI?: boolean;
  onChange?: (bounds: {
    northEast: LocationModel;
    southWest: LocationModel;
  }) => void;
}

const MapComponent: FC<Props> = ({
  children,
  center,
  zoom,
  locations,
  mapStyle,
  fitLocations,
  disableDefaultUI,
  onChange,
}) => {
  const [map, setMap] = useState<google.maps.Map<Element>>(null);
  const [bounds, setBounds] = useState<BoundsModel>(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GATSBY_GOOGLE_API_KEY,
  });

  useEffect(() => {
    if (map && locations) {
      if (fitLocations || !center) {
        const bounds = new google.maps.LatLngBounds();
        locations.forEach((location) => bounds.extend(toLatLng(location)));
        if (center) {
          bounds.extend(toLatLng(center));
        }
        map.setCenter(bounds.getCenter());
        map.fitBounds(bounds);
        console.log('zoom', map.getZoom());
      }
    }
  }, [map, locations]);

  const onLoad = (map: google.maps.Map<Element>) => {
    setMap(map);
  };

  const onUnmount = () => {
    setMap(null);
  };

  const onIdle = useCallback(() => {
    if (!onChange) {
      return;
    }
    const mapBounds = map?.getBounds();
    if (!mapBounds) {
      return;
    }
    const northEast = mapBounds?.getNorthEast();
    const southWest = mapBounds?.getSouthWest();
    if (!northEast || !northEast) {
      return;
    }
    const newBounds = {
      northEast: {
        latitude: northEast.lat(),
        longitude: northEast.lng(),
      },
      southWest: {
        latitude: southWest.lat(),
        longitude: southWest.lng(),
      },
    };

    // if (
    //   bounds &&
    //   bounds.northEast.latitude === newBounds.northEast.latitude &&
    //   bounds.northEast.longitude === newBounds.northEast.longitude &&
    //   bounds.southWest.latitude === newBounds.southWest.latitude &&
    //   bounds.southWest.longitude === newBounds.southWest.longitude
    // ) {
    //   return;
    // }
    //setBounds(newBounds);
    onChange(newBounds);
  }, [map, onChange]);

  console.log('Map', bounds);

  const googleCenter = useMemo(() => toLatLng(center), [center]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={mapStyle || containerStyle}
      center={googleCenter}
      zoom={zoom || 10}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onIdle={onIdle}
      options={{ disableDefaultUI }}
    >
      {children}
    </GoogleMap>
  ) : null;
};

export const Map: FC<Props> = React.memo(MapComponent);

export const toLatLng = (location: LocationModel): google.maps.LatLngLiteral =>
  location
    ? {
        lat: location.latitude,
        lng: location.longitude,
      }
    : null;

export const toLocation = (
  location: google.maps.LatLngLiteral
): LocationModel =>
  location
    ? {
        latitude: location.lat,
        longitude: location.lng,
      }
    : null;
